import React from 'react';
import {Link} from 'gatsby';

import logo from '../images/logo.png';
import open_access from '../images/open_access_black.png';

import dictionary from '../data/_dictionary';
import locales from '../data/_locales';

const Header = ({lang, path}) => (
    <div className="header ornamented">
        <div className="header-top">
            <div className="info">
                {`ISSN: 2299-4122 • e-ISSN: 2657-4799 • DOI: 10.23817/bzspr • ${dictionary.bounceRate[lang]}: 22% (2024)`}
            </div>
            <div className="header-top-right">
                <a className="creative-commons-info-link" href={dictionary._linkOpenAccess[lang]} target="_blank" rel="noopener noreferrer">
                    <img  className="creative-commons-info-logo" src={open_access} alt="open access" />
                </a>
                <ul className="header-navigation-list">
                    {locales.map((locale, i) => (
                        <li key={i} className={`header-navigation-list-item ${locale.lang === lang && 'enabled'}`}>
                            <Link to={path && path.replace(`/${lang}/`, `/${locale.lang}/`)}>{locale.label}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
        <div className="header-bottom">
            <img  className="logo" src={logo} alt="" />
            <div className="title-container">
                <div className="title">
                    Beiträge zur allgemeinen und vergleichenden Sprachwissenschaft
                </div>
                <div className="title-separator" />
                <div className="title">
                    Contributions to General and Comparative Linguistics
                </div>
            </div>
        </div>
    </div>
);

export default Header;
